<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <invoice-type-form :invoice-type="invoiceType" >
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </invoice-type-form>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import CallbackButton from "../../../components/elements/callback-button.vue";
import InvoiceTypeForm from "../../../components/forms/invoice-type-form.vue";

export default {
    components: {InvoiceTypeForm, CallbackButton},

    computed: {
        ...mapGetters({
            invoiceType: 'invoiceType/item'
        }),

        title: function () {
            return this.invoiceType.id ? this.$tc('invoices::types.type', 2).ucFirst() + ' / ' + (this.invoiceType?.name) : this.$t('base.create_new_item', {item: this.$tc('invoices::types.type', 1)}).ucFirst()
        },

        link: function () {
            const path = '/master-data/invoices/types';
            return {path: this.$t('routes.' + path)}
        }
    },

    methods: {
        back: function () {
            this.$router.push(this.link)
        },
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('invoiceType/show', {id: this.$route.params.id})
        }
    },

    unmounted() {
        this.$store.dispatch('invoiceType/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
